@import 'src/styles/config';
.accordionContainer {
}

.accordionTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  max-width: 100%;
  height: auto;
  background-color: rgba(84, 84, 84, 0.3);
  cursor: pointer;
  margin: 0;
  border-radius: 8px;

  &.show {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:hover {
    background-color: rgba(84, 84, 84, 0.4);
  }

  &:active {
    background-color: $base10;
  }

  img {
    display: block;
    width: 24px;
    height: 24px;
  }
}

.toggle_down {
  transform: rotate(0deg);
  transition: all 0.3s linear;
}

.toggle_up {
  transform: rotate(180deg);
  transition: all 0.3s linear;
}

.accordionHolder {
  overflow: hidden;
  margin: 0;
  max-height: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.3s ease-in-out;
}

.accordionContent {
  padding: 16px;
  font-size: 14px;
  background-color: #191919;
  border-radius: 0 0 8px 8px;
}

.showContent {
  background-color: #000;
  max-height: 10000px;
  transform: scaleY(1);
  border-radius: 0 0 $border-radius-default $border-radius-default;
}
