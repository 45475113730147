@import 'src/styles/config';
.optInButtonWrapper {
  padding: 16px 8px;
  border-radius: 8px;
  background-color: $base20;
  text-align: center;
  margin: 0;
}

.text {
  font-size: 14px;
  color: $base90;
}

.optInButton,
.optOutButton {
  width: auto;
  min-width: auto;
  min-height: auto;
  max-height: 34px;
  white-space: nowrap;
  padding: 8px 16px;
  line-height: 1;
  margin: 16px auto 0;
  column-gap: 4px;
  font-weight: $font-weight-600;
  font-size: 14px;
  border-radius: $border-radius-default;
}

.optInButton {
  @include button-primary-colors;

  &:focus:hover {
    background-color: $brandAccentSecondary50;
  }
}

.optOutButton {
  color: $base100;

  &:global(.mt-button-outlined) {
    @include button-secondary-colors;

    &:disabled {
      background-color: $brandAccentSecondary25;
    }
  }
}
