@import 'src/styles/config';
$card-width: 269px;
$card-width-mobile: 224px;
$card-height: 208px;
$card-height-mobile: 175.81px;
$green-label-spacing: 4px; // [FYI]: Need for full showing Free SC label

.root {
  display: flex;
  gap: 10px;
  margin: $sizing1d5Rem calc($green-label-spacing * -1) 23px;
  padding: 0 $green-label-spacing 10px;
}

.arrowBtn:has(button) {
  :global(.prev-banner-arrow),
  :global(.next-banner-arrow) {
    background-color: transparent;
    width: 42px;
    height: 42px;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
  }

  :global(.prev-banner-arrow) {
    transform: translateX(-50%);
    left: 8px;
    margin: 0;
  }

  :global(.next-banner-arrow) {
    transform: translateX(50%) rotate(180deg);
    right: 5px;
    margin: 0;
  }
}

.skeleton {
  display: flex;
  gap: $spacing0d75Rem;
  width: 100%;
  margin: $sizing1d5Rem 0 33px;
  padding: 0;
  overflow: hidden;
}

.skeletonItem {
  width: $card-width;
  min-width: $card-width;
  height: $card-height;
  margin-top: $spacing1d5Rem;

  @media screen and (max-width: $sm) {
    width: $card-width-mobile;
    min-width: $card-width-mobile;
    height: $card-height-mobile;
    margin-top: $spacing1Rem;
  }
}

.titleOffersFallback {
  min-height: $sizing3Rem;
  margin: $spacing1d5Rem 0 $spacing0d75Rem;

  @media screen and (max-width: $sm) {
    min-height: $sizing2Rem;
    margin: $spacing1d5Rem 0 $spacing0d5Rem;
  }
}

.noOffers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: $card-height + $spacing1d5Rem;
  margin: $sizing1d5Rem 0 33px;
  color: $base100;

  @media screen and (max-width: $sm) {
    height: $card-height-mobile + $spacing1Rem;
  }

  .button {
    margin-top: $spacing1d5Rem;

    @media screen and (max-width: $sm) {
      margin-top: $spacing1Rem;
    }
  }
}
