@import 'src/styles/config';
.root {
  margin: 16px auto;
  min-height: 40vh;
  // mobile
  @media screen and (max-width: $xs) {
    padding: 0;
  }
}

.content {
  padding: 32px;
  margin-bottom: 16px;
  background-color: $base10;

  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  @media screen and (max-width: $sm) {
    padding: 38px 16px;
  }
}

.sticky {
  position: sticky;
  top: calc($headerHeight + env(safe-area-inset-top));
  z-index: 2;

  @media screen and (max-width: $sm) {
    top: calc($mobileHeaderHeight + env(safe-area-inset-top));
  }
}
