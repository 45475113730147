@import 'src/styles/config';
.shareButton {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
  padding: 0 20px;
  width: 100%;

  img {
    display: block;
    width: 16px;
  }
}
