@import 'src/styles/config';
.linkHolder {
  font-weight: $font-weight-500;
  text-decoration: underline;
  color: #fbb400;
  margin-top: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.button {
  width: 100%;
  margin-left: 0;
  margin-right: 0;

  & + .button {
    margin-bottom: 0;
  }
}

.label {
  line-height: 1.8;
  font-size: 14px;

  a {
    text-decoration: none;
    color: $base100;

    &:hover {
      text-decoration: underline;
    }
  }
}

.checkbox {
  color: #fff;
}

.checkboxInput {
  margin-bottom: 8px;
  margin-right: 0;
}

.checkboxError {
  color: #ffff00;
}

.error {
  color: #ffff00;
  margin-top: 0;
}
