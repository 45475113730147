@import 'src/styles/config';
.backButtonWrapper {
  min-height: auto !important;

  @media screen and (max-width: $sm) {
    padding: 0;
  }
}

.backButton {
  float: left;
}
