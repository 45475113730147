@import 'src/styles/config';
.root {
  counter-reset: section;

  @include body1;

  div {
    line-height: 1.5 !important;
  }

  span {
    * {
      margin: 0;
      padding: 0;
    }

    h1 {
      @include h4;
    }

    h2 {
      margin-bottom: 1rem;
    }

    h4 {
      @include h6;

      counter-increment: section;
      margin-bottom: 1rem;
    }

    h6 {
      @include h6;

      font-size: 1rem;
    }

    p {
      margin-bottom: 16px !important;
    }

    ul,
    ol {
      padding: 16px;
      padding-top: 0;
      margin-left: 16px;

      li {
        width: 100%;
        position: relative;
        box-sizing: border-box;
        text-align: left;
        padding: 4px 0;
        text-decoration: none;

        @include body1;

        p {
          margin: 0;
          display: inline;
        }
      }
    }

    table {
      margin: auto;
      margin-bottom: 1.5rem;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

      @media (max-width: 440px) {
        font-size: 0.7rem;
      }

      li {
        @media (max-width: 440px) {
          font-size: 0.7rem;
        }
      }
    }

    th {
      opacity: 0.8;
    }

    td,
    th {
      padding: 0.3rem 1.5rem;
      border-bottom: 1px solid #e0e0e0;
      text-align: center;

      @media (max-width: 630px) {
        padding: 5px;
      }
    }

    td hr {
      margin: 10px 0;
      height: 1px;
      border: none;
      border-top: solid 1px #e0e0e0;
      width: calc(100% + 3rem);
      transform: translateX(-1.5rem);

      @media (max-width: 630px) {
        width: calc(100% + 10px);
        transform: translateX(-5px);
      }
    }

    tbody tr:last-child td {
      border-bottom: none;
    }
  }

  & :global(.list-with-sublist) {
    list-style: none;
    display: flex;

    p {
      display: flex;

      span {
        margin-right: 2px;
      }
    }

    span {
      margin-right: 2px;
    }
  }
}
