@import 'src/styles/config';
$card-width: 269px;
$card-width-mobile: 224px;

.root {
  position: relative;
  width: $card-width;
  min-width: $card-width;
  background: #1c1c1c;
  margin-top: $spacing1d5Rem;
  display: flex;
  flex-direction: column;
  height: max-content;
  border-radius: $border-radius-8;

  @media screen and (max-width: $sm) {
    width: $card-width-mobile;
    min-width: $card-width-mobile;
    margin-top: $spacing1Rem;
  }

  .specialIcon {
    height: 88px;
    margin: -$spacing1d5Rem 0 $spacing0d5Rem;
    align-self: center;

    @media screen and (max-width: $sm) {
      height: 68.62px;
      margin-top: -$spacing1Rem;
    }
  }

  .title {
    @include headlines-headlineH5-general;

    text-align: center;
    margin: 0;

    @media screen and (max-width: $sm) {
      @include headlines-headlineH6-general;
    }
  }

  .button {
    margin: $spacing0d75Rem;
    height: $sizing3Rem;
    display: flex;
    flex-direction: column;
    gap: 1px;

    @include button-buttonMD-general;

    @media screen and (max-width: $sm) {
      height: $sizing2d5Rem;

      @include button-buttonSM-general;
    }
  }

  .benefits {
    width: 100%;
    max-width: 100%;
    align-items: center;
    min-height: $sizing2d5Rem;
    background: #252525;
    overflow: hidden;
    display: flex;
    border-radius: 0 0 $border-radius-8 $border-radius-8;

    @media screen and (max-width: $sm) {
      min-height: $sizing2Rem;
    }

    .features {
      display: flex;
      animation: carousel 10s linear infinite;
      animation-delay: 1.5s;
    }

    .feature {
      white-space: nowrap;
      padding: 0 calc(($card-width / 4) / 2);
      display: flex;
      align-items: center;
      justify-content: center;
      color: $base100;
      font-weight: $fontWeightPrimaryBold;
      line-height: 140%;
      font-size: $fontMD;

      @media screen and (max-width: $md) {
        font-size: $fontXS;
        line-height: 100%;
      }

      @media screen and (max-width: $sm) {
        padding: 0 calc(($card-width-mobile / 4) / 2);
      }
    }

    .currencyIcon {
      display: flex;
      align-items: center;
      min-width: 21px;
      margin-right: 5px;

      @media screen and (max-width: $md) {
        margin-right: 3px;
        min-width: auto;

        img {
          height: 12px;
        }
      }
    }
  }
}

.dailyBenefits {
  justify-content: center;
  color: $base100;
  font-size: 14px;
}

@keyframes carousel {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.countdown {
  background: #4d4d4d;
  color: #7e7e7e;
  display: flex;
  align-items: center;
  gap: 4px;
  width: 250px;
  height: 48px;
  margin: -20px;
  padding-left: 25px;
  font-size: 18px;
  border-radius: 8px;

  @media screen and (max-width: $sm) {
    width: 200px;
    height: $sizing2d5Rem;
    font-size: 14px;
  }
}

.clockIcon {
  margin-left: 4px;

  path {
    fill: #7e7e7e !important;
  }
}

.timer {
  text-align: start;
  width: 55px;
}
