@import 'src/styles/config';
.root {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: center;

  // desktop
  @media screen and (min-width: $sm) {
    min-height: 70vh;
  }
}

.title {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 2rem;
}
