@import 'src/styles/config';
.root {
  position: absolute;
  top: 16px;
  left: -4px;

  & svg {
    width: 69px;
    height: 40px;
  }

  .text,
  & > span {
    position: absolute;
    top: 7px;
    left: 0;
    padding: 0 5px;
    color: $base0;
    font-size: $fontMD;
    line-height: 1;
    font-weight: 700;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
