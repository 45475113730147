@import 'src/styles/config';
.wrapper {
  margin-top: 20px;
  min-height: 310px;

  @media screen and (max-width: $sm) {
    min-height: 270px;
  }
}

.title {
  color: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  font-weight: inherit;
  font-size: inherit;
  text-align: inherit;
  font-family: inherit;
  margin: 0;
}
