@import 'src/styles/config';
.tilesContainer {
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: repeat(6, auto);
  gap: 12px;

  @media screen and (max-width: $sm) {
    gap: 8px;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(4, auto);
  }

  @media screen and (max-width: 420px) {
    grid-template-columns: repeat(3, auto);
  }
}
