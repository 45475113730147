@import 'src/styles/config';
$jp-container-min-height-mobile: 474px;
$jp-container-min-height-desktop: 551px;
$jp-container-min-height-compact-mobile: 340px;
$jp-container-min-height-compact-desktop: 380px;

.root {
  [class*='gliderDot'] button {
    width: 55px;
    height: 3px;
    background-color: rgba(165, 165, 165, 0.6);
  }

  button[class*='activeGliderDot'] {
    background-color: $primaryColor;
  }
}

.container {
  list-style: none;
  padding: 40px 0;
  gap: 12px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(330px, 50%);
  grid-gap: 16px;
  min-height: $jp-container-min-height-desktop;

  @media screen and (max-width: $sm) {
    padding: 40px 12px 0;
    min-height: $jp-container-min-height-mobile;

    &:has(> *:only-child) {
      justify-content: center;
    }
  }

  &.compact {
    grid-auto-columns: minmax(350px, 50%);
    min-height: $jp-container-min-height-compact-desktop;

    @media screen and (max-width: $sm) {
      min-height: $jp-container-min-height-compact-mobile;
    }

    @media screen and (max-width: 350px) {
      grid-auto-columns: minmax(255px, 50%);
    }
  }
}
