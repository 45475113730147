@import 'src/styles/config';
.oldPrice {
  line-height: 100%;
  color: #4d3500;
  text-transform: capitalize;
  font-weight: 400;
}

.lineThrough {
  text-decoration: line-through;
  text-decoration-color: #ff0000;
}

.sm_text {
  font-size: 12px;
}
