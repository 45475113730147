@import 'src/styles/config';
.root {
  position: absolute;
  top: 1px;
  left: -2px;

  .text,
  & > span {
    position: absolute;
    top: 28%;
    left: 33%;
    transform: translate(-50%, -50%) rotate(-42deg);
    padding: 0 5px;
    color: $base0;
    font-size: 9px;
    line-height: 1;
    font-weight: 700;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
