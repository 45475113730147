@import 'src/styles/config';
$card-width: 269px;
$card-width-mobile: 224px;

.root {
  position: relative;
  width: $card-width;
  min-width: $card-width;
  background: #1c1c1c;
  margin-top: $spacing1d5Rem;
  display: flex;
  flex-direction: column;
  height: max-content;
  border-radius: $border-radius-8;

  @media screen and (max-width: $sm) {
    width: $card-width-mobile;
    min-width: $card-width-mobile;
    margin-top: $spacing1Rem;
  }

  .specialIcon {
    height: 88px;
    margin: -24px 0 $spacing0d5Rem $spacing0d75Rem;
    align-self: center;

    @media screen and (max-width: $sm) {
      height: 68px;
      margin-top: -$spacing1Rem;
      margin-left: $spacing0d75Rem;
    }
  }

  .title {
    @include headlines-headlineH5-general;

    text-align: center;
    margin: 0;

    @media screen and (max-width: $sm) {
      @include headlines-headlineH6-general;
    }
  }

  .button {
    margin: $spacing0d75Rem;
    height: $sizing3Rem;
    display: flex;
    flex-direction: column;
    gap: 1px;

    > div,
    button {
      width: 100%;
      height: 100%;
      background-color: #fbab00;
      font-size: 16px;
      margin: 0;
    }

    @include button-buttonMD-general;

    @media screen and (max-width: $sm) {
      height: $sizing2d5Rem;

      @include button-buttonSM-general;

      > div,
      button {
        font-size: 14px;
      }
    }

    button {
      @include button-primary-colors;
    }
  }

  .benefits {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    min-height: $sizing2d5Rem;
    background: #252525;
    overflow: hidden;
    display: flex;
    border-radius: 0 0 $border-radius-8 $border-radius-8;

    @media screen and (max-width: $sm) {
      min-height: $sizing2Rem;
    }

    .features {
      display: flex;
      align-items: center;
    }

    .animated {
      animation: carousel 10s linear infinite;
      animation-delay: 1.5s;
    }

    .featureText {
      color: $base100;
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 1.4;
      font-weight: 400;
      white-space: pre;

      @media screen and (max-width: $sm) {
        font-size: 12px;
      }
    }

    .currencyIcon {
      height: 20px;
      margin-right: 3px;

      img {
        height: 100%;
        width: auto;
      }

      @media screen and (max-width: $sm) {
        height: 12px;
        min-width: auto;
      }
    }
  }

  .shareButton {
    height: 48px;
    margin: 12px;
    padding: 6px 15px;
    width: auto;

    @media screen and (max-width: $sm) {
      height: 40px;
    }
  }
}

.dailyBenefits {
  justify-content: center;
  color: $base100;
  font-size: 14px;
}

.linkInfo {
  height: 16px;
  text-decoration: none;

  svg {
    width: 16px;
    height: 16px;
    margin-left: 3px;
  }
}

@keyframes carousel {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}
